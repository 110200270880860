import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EmployeeDetails from './EmployeeDetails';

const Departments = () => {
  const [departmentCounts, setDepartmentCounts] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDepartmentCounts = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          console.error('Token not found in localStorage');
          return;
        }

        const response = await axios.get('https://backendcc.marsbpo.org/api/employees/department-employee-count', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setDepartmentCounts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching department employee counts:', error);
      }
    };

    fetchDepartmentCounts();
  }, []);

  const handleCardClick = (department) => {
    setSelectedDepartment(department);
  };

  return (
    <div className="bg-white p-8 min-h-screen">
    <div className="container mx-auto">
      {loading && <p>Loading...</p>}
      {!loading && departmentCounts.length === 0 && <p>No departments found</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 masonry-grid">
        {departmentCounts.map((department) => (
          <div
          style={{backgroundColor: '#F4F4F4'}}
            key={department.department}
            className=" shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition duration-300 ease-in-out transform hover:scale-105 mb-4"
          >
            <div className="border-b-2 border-black p-3 bg-blue-950 text-white">
              <h3 className="text-lg font-semibold">{department.department}</h3>
            </div>
            <div className="p-3">
              <h5 className="mb-1 text-sm font-medium text-gray-800">
                Total Employees: {department.count}
              </h5>
              <button
                type="button"
                onClick={() => handleCardClick(department.department)}
                className="inline-block px-3 py-1 text-xs font-medium uppercase bg-blue-950 text-white rounded-full focus:outline-none focus:shadow-outline-purple hover:bg-blue-900"
              >
                Show Employees
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  
    {selectedDepartment && (
      <EmployeeDetails department={selectedDepartment} />
    )}
  </div>
  );
};

export default Departments;
