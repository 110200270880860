import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

const EmployeeDetails = ({ department }) => {
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(25);
  const [selectedTimeShift, setSelectedTimeShift] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found in localStorage");
        }
        const response = await axios.get(
          `https://backendcc.marsbpo.org/api/employees/department/${department}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [department]);

  const filteredEmployees = employees.filter(employee => {
    if (!selectedTimeShift || selectedTimeShift === "" || selectedTimeShift === "Select a Time Shift") {
      return true;
    } else {
      return employee.timeShift && employee.timeShift.toLowerCase().includes(selectedTimeShift.toLowerCase());
    }
  });

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEmployees.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleViewProfile = (employee) => {
    navigate(`/employee/profile/${employee.id}`, { state: { employee } });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className="mt-8 p-8 bg-white rounded-lg shadow-lg overflow-x-auto">
        <h3 className="text-2xl font-bold mb-4">Employees in {department}</h3>
        {/* <div className="relative">
          <button
            className="flex items-center rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
            type="button"
            onClick={toggleDropdown}
            id="dropdownMenuButton1e"
            aria-expanded={isDropdownOpen ? "true" : "false"}
            data-twe-ripple-init
            data-twe-ripple-color="light"
          >
            {selectedTimeShift ? selectedTimeShift : "Select a Time Shift"}
            <span className="ms-2 w-2 h-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button>
          <ul
            className={`absolute z-10 left-0 m-0 ${isDropdownOpen ? 'block' : 'hidden'} min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg dark:bg-surface-dark`}
            aria-labelledby="dropdownMenuButton1e"
            data-twe-dropdown-menu-ref
          >
            <li>
              <a
                className="block w-full whitespace-nowrap bg-white px-4 py-2 text-sm font-normal text-black hover:bg-gray-200 focus:bg-gray-200 focus:outline-none active:bg-gray-300 active:no-underline dark:bg-surface-dark dark:text-black dark:hover:bg-gray-800/25 dark:focus:bg-gray-800/25 dark:active:bg-gray-800/25"
                href="#"
                data-twe-dropdown-item-ref
                onClick={() => {setSelectedTimeShift("Select a Time Shift"); toggleDropdown();}}
              >
                Select a Time Shift
              </a>
            </li>
            <li>
              <a
                className="block w-full whitespace-nowrap bg-white px-4 py-2 text-sm font-normal text-black hover:bg-gray-200 focus:bg-gray-200 focus:outline-none active:bg-gray-300 active:no-underline dark:bg-surface-dark dark:text-black dark:hover:bg-gray-800/25 dark:focus:bg-gray-800/25 dark:active:bg-gray-800/25"
                href="#"
                data-twe-dropdown-item-ref
                onClick={() => {setSelectedTimeShift("01:00pm TO 10:00pm"); toggleDropdown();}}
              >
                01:00pm TO 10:00pm
              </a>
            </li>
            <li>
              <a
                className="block w-full whitespace-nowrap bg-white px-4 py-2 text-sm font-normal text-black hover:bg-gray-200 focus:bg-gray-200 focus:outline-none active:bg-gray-300 active:no-underline dark:bg-surface-dark dark:text-black dark:hover:bg-gray-800/25 dark:focus:bg-gray-800/25 dark:active:bg-gray-800/25"
                href="#"
                data-twe-dropdown-item-ref
                onClick={() => {setSelectedTimeShift("03:00pm TO 12:00am"); toggleDropdown();}}
              >
                03:00pm TO 12:00am
              </a>
            </li>
          </ul>
        </div> */}
        <table className="min-w-full">
          {/* Table header */}
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Name
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Role
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Department
              </th>
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Base Salary
              </th>
              {/* <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Time Shift
              </th> */}
              <th className="py-3 px-4 text-left font-medium text-gray-600 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
            {currentItems.map((employee) => (
              <tr
                key={employee.id}
                className="border-t border-gray-300 hover:bg-gray-50 transition-all duration-300"
              >
                <td className="py-3 px-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={employee.profileImage || "https://via.placeholder.com/150"}
                        alt={`${employee.employeeName}'s profile`}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{employee.employeeName}</div>
                      <div className="text-sm text-gray-500">{employee.email}</div>
                    </div>
                  </div>
                </td>
                <td className="py-3 px-4 whitespace-nowrap">{employee.role}</td>
                <td className="py-3 px-4 whitespace-nowrap">{employee.department}</td>
                <td className="py-3 px-4 whitespace-nowrap">PKR {employee.salary}</td>
                {/* <td className="py-3 px-4 whitespace-nowrap">{employee.timeShift}</td> */}
                <td className="py-3 px-4 whitespace-nowrap flex items-center space-x-2">
                  <button
                    onClick={() => handleViewProfile(employee)}
                    className="bg-blue-950 text-white px-3 py-1 rounded-full hover:bg-blue-800 focus:outline-none focus:shadow-outline-blue"
                  >
                    View Profile
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center  my-4">
        <ReactPaginate
          pageCount={Math.ceil(filteredEmployees.length / itemsPerPage)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="bg-white text-black rounded-md px-3 py-1"
          previousLabel="Previous"
          nextLabel="Next"
        />
      </div>
    </>
  );
};

export default EmployeeDetails;
