import React from 'react';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <h1 className="text-5xl font-bold text-gray-800">404</h1>
      <p className="mt-4 text-xl text-gray-600">Oops! The page you're looking for can't be found.</p>
      <div className="mt-6">
      <svg className="animate-bounce  mx-auto h-16 w-16 text-blue-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
        </svg>
      </div>
      <a href="/" className="mt-8 px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-700">
        Back to Home
      </a>
    </div>
  );
};

export default NotFound;
