import React, { useState } from 'react';
import FileUpload from './FileUpload';
import EmployeeFormUpload from './EmployeeFormUpload';

const AddEmployee = () => {
  const [selectedUploadType, setSelectedUploadType] = useState('form');

  return (
    <div className="container mx-auto p-8">
      <div className="flex flex-col md:flex-row items-center mb-8">
        <button
        // style={{ backgroundColor: '#F4F4F4'}}
          className={`mr-0 md:mr-4 mb-4 md:mb-0 px-6 py-3 rounded-full focus:outline-none ${
            selectedUploadType === 'form' ? ' text-white bg-blue-950' : 'bg-gray-200 text-black shadow-lg'
          }`}
          onClick={() => setSelectedUploadType('form')}
        >
          <span className="text-base font-medium">Form Upload</span>
        </button>
        <button
          className={`px-6 py-3 rounded-full focus:outline-none ${
            selectedUploadType === 'excel' ? ' text-white bg-blue-950' : 'bg-gray-200 text-black shadow-lg'
          }`}
          onClick={() => setSelectedUploadType('excel')}
        >
          <span className="text-base font-medium">Excel Upload</span>
        </button>
      </div>

      {/* <div  className="p-8"> */}
        {/* <h2 className="text-2xl font-bold mb-6 text-center">
          {selectedUploadType === 'form' ? 'Add Employee' : 'Upload Employee Details'}
        </h2> */}
        {selectedUploadType === 'form' ? (
          <EmployeeFormUpload />
        ) : (
          <FileUpload />
        )}
      </div>
    // </div>
  );
};

export default AddEmployee;
