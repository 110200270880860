import React, { useState, useRef } from 'react';
import axios from 'axios';
import logo from "../assets/logo.png"
import html2pdf from 'html2pdf.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AttendanceSearch = () => {
  const [employeeName, setEmployeeName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef(null);

  const handleSearch = async () => {
    try {
      if (!employeeName || !startDate || !endDate) {
        throw new Error('Please fill in all fields.');
      }

      setIsLoading(true);

      const payload = { employeeName, startDate, endDate };
      const apiUrl = 'https://backendcc.marsbpo.org/api/attendance/getAttendanceDetailsByName';
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found in localStorage. Redirect to login or handle accordingly.');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(apiUrl, payload, { headers });
      setAttendanceDetails(response.data);
      toast.success('Attendance details fetched successfully.');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };


  const generatePDF = () => {
    try {
      setIsLoading(true);
      const content = generatePDFContent();

      const element = document.createElement('div');
      element.innerHTML = content;

      html2pdf(element, {
        margin: 1,
        filename: 'attendance_report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      });

      toast.success('PDF report generated successfully.');
    } catch (error) {
      toast.error('Error generating PDF report. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  const generatePDFContent = () => {
    const today = new Date().toLocaleDateString();
    let content = `
      <div style="text-align: center;">
        <img src="${logo}" alt="Logo" style="width: 100px; height: 100px; margin-bottom: 60px;">
        <div style="float: right; margin-top: -100px;">
          <p style="margin-bottom: 10px; font-weight: bold;">Date: ${today}</p>
        </div>
        <h1 style="margin-bottom: 40px; font-weight: bold; font-size: 24px;">Attendance Report</h1>
      </div>
    `;

    if (attendanceDetails.length > 0) {
      content += `
        <table style="width: 100%; border-collapse: collapse; margin: 0 auto;">
          <tr style="background-color: #f2f2f2;">
            <th style="padding: 10px; border: 1px solid #dddddd; text-align: left;">Employee Name</th>
            <th style="padding: 10px; border: 1px solid #dddddd; text-align: left;">Date</th>
            <th style="padding: 10px; border: 1px solid #dddddd; text-align: left;">Status</th>
          </tr>
      `;

      attendanceDetails.forEach(attendance => {
        content += `
          <tr>
            <td style="padding: 10px; border: 1px solid #dddddd; text-align: left;">${attendance.name}</td>
            <td style="padding: 10px; border: 1px solid #dddddd; text-align: left;">${attendance.date}</td>
            <td style="padding: 10px; border: 1px solid #dddddd; text-align: left;">${attendance.status}</td>
          </tr>
        `;
      });

      content += '</table>';
    } else {
      content += '<p>No attendance data available.</p>';
    }

    return content;
  };

  return (
    <div className="max-w-screen-lg mx-auto p-4 mt-12">
      <div className="bg-white border-t-4 border-blue-950 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-300 ease-in-out">
        <div className="p-4">
          <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 items-start">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <label className="mr-2">Employee Name:</label>
              <input
                type="text"
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
                className="border rounded p-2"
              />
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <label className="mr-2">Start Date:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border rounded p-2"
              />
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <label className="mr-2">End Date:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border rounded p-2"
              />
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <button onClick={handleSearch} className="bg-blue-950 text-white rounded px-4 py-2">
                Search
              </button>
              {attendanceDetails.length > 0 && (
                <button onClick={generatePDF} className="bg-blue-600 text-white rounded px-4 py-2" disabled={isLoading}>
                  Generate PDF Report
                </button>
              )}
              {isLoading && (
                <div className="loader"></div>
              )}
            </div>
          </div>

          <div className="mt-4" ref={tableRef}>
            <h2 className="text-lg font-semibold mb-2">Attendance Details:</h2>
            <div className="overflow-x-auto">
              { attendanceDetails.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee Name</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {attendanceDetails.map((attendance, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.date}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-red-500 font-bold">No attendance data available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceSearch;
