import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import logo from "../../assets/logo.png";
import html2pdf from "html2pdf.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeInventory = ({ fetchTotalInventory }) => {
  const [issuedInventory, setIssuedInventory] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchIssuedInventory();
  }, [currentPage]);

  useEffect(() => {
    if (employees.length > 0 && !selectedEmployee) {
      setSelectedEmployee(employees[0]);
    }
  }, [employees, selectedEmployee]);

  const fetchIssuedInventory = async () => {
    try {
      const response = await axios.get(`https://backendcc.marsbpo.org/api/issue-inventory/get-issued-inventory?page=${currentPage}`);
      const inventoryData = response.data.data;

      const employeeNames = inventoryData.reduce((names, item) => {
        if (!names.includes(item.Employee.employeeName)) {
          names.push(item.Employee.employeeName);
        }
        return names;
      }, []);

      setIssuedInventory(inventoryData);
      setEmployees(employeeNames);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching issued inventory:', error);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleTabClick = (employeeName) => {
    setSelectedEmployee(employeeName);
  };

  const handleDelete = async (itemId) => {
    try {
      await axios.delete(`https://backendcc.marsbpo.org/api/issue-inventory/delete/${itemId}`);
      await fetchTotalInventory(); // Fetch total inventory after deletion
      fetchIssuedInventory(); // Fetch issued inventory after deletion
      toast.success('Inventory item deleted successfully');
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Error deleting inventory item');
    }
  };

  const exportAllToExcel = async () => {
    try {
      const response = await axios.get(`https://backendcc.marsbpo.org/api/issue-inventory/export-to-excel`, {
        responseType: 'arraybuffer' // Set responseType to arraybuffer
      });

      const currentDate = new Date().toLocaleDateString();

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `issued_inventory_report_${currentDate}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };

  const generatePDFContent = () => {
    const currentDate = new Date().toLocaleDateString();

    let content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Issued Inventory Report</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }

          .header {
            text-align: center;
            margin-bottom: 30px;
          }

          .logo {
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
          }

          .date {
            text-align: right;
            margin-right: 20px;
          }

          .table-container {
            margin: 0 auto;
            padding: 20px;
            width: 90%;
          }

          table {
            width: 100%;
            border-collapse: collapse;
            margin: 0 auto;
          }

          th, td {
            padding: 10px;
            border: 1px solid #ddd;
            text-align: left;
          }

          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <img src="${logo}" alt="Logo" class="logo">
          <h1>Issued Inventory Report</h1>
          <p class="date">Date: ${currentDate}</p>
        </div>
        <div class="table-container">
    `;

    if (issuedInventory.length > 0) {
      content += `
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Issue Date</th>
              <th>Issued By</th>
            </tr>
          </thead>
          <tbody>
      `;
        issuedInventory.forEach((item, index) => {
          const id = index + 1; // Generate ID starting from 1
          content += `
        <tr>
          <td>${id}</td>
          <td>${item.Inventory.itemName}</td>
          <td>${item.quantity}</td>
          <td>${formatDateToISO(item.issueDate)}</td>
          <td>${item.issuedBy}</td>
        </tr>
      `;
        });

        content += `
        </tbody>
      </table>
    `;
      } else {
        content += "<p>No issued inventory data available.</p>"; // Message for empty data
      }

      content += `
    </body>
    </html>
  `;

      return content;
    };

  const exportAllToPDF = async () => {
    try {
      const content = generatePDFContent();

      await html2pdf().from(content).save('issued_inventory_report.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };

  // Filter inventory based on selected employee
  const filteredInventory = selectedEmployee ? issuedInventory.filter(item => item.Employee.employeeName === selectedEmployee) : [];

  return (
    <div className="container mx-auto p-8">
      <ToastContainer />
      <h2 className="text-4xl font-bold text-gray-800 mb-8">Issued Inventory</h2>
      <div className="flex">
        <div className="w-1/4">
          <ul className="text-center">
            {employees.map((employeeName) => (
              <li key={employeeName} onClick={() => handleTabClick(employeeName)} className={`cursor-pointer py-2 ${selectedEmployee === employeeName ? 'bg-gray-200' : ''}`} style={{ fontWeight: '600' }}>{employeeName}</li>
            ))}
          </ul>
        </div>

        <div className="w-3/4 pr-8">
          <div className="table-container">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">ID</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Item</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Quantity</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Issue Date</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Issued By</th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredInventory.map((item, index) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.Inventory.itemName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.quantity}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDateToISO(item.issueDate)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.issuedBy}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-left">
                      <button onClick={() => handleDelete(item.id)} className="text-red-600 hover:text-red-800 bg-red-200 hover:bg-red-300 rounded-full px-4 py-2 cursor-pointer">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-6">
            <button onClick={exportAllToExcel} className="bg-green-500 text-white px-4 py-2 rounded-md mr-4">Export All to Excel</button>
            <button onClick={exportAllToPDF} className="bg-blue-500 text-white px-4 py-2 rounded-md">Export All to PDF</button>
          </div>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'flex justify-center mt-6'}
            pageClassName={'mr-2 bg-gray-200 rounded-full px-4 py-2 cursor-pointer'}
            previousClassName={'bg-gray-200 rounded-full px-4 py-2 cursor-pointer'}
            nextClassName={'bg-gray-200 rounded-full px-4 py-2 cursor-pointer'}
            activeClassName={'bg-gray-400 text-white'}
            previousLinkClassName={'text-gray-700'}
            nextLinkClassName={'text-gray-700'}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeInventory;
