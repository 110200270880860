import React  from 'react'
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useMemo, useState } from "react";
const BackgroundAnimation = () => {

    // eslint-disable-next-line
    const [init, setInit] = useState(false);

    useEffect(() => {
      initParticlesEngine(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadAll(engine);
        //await loadFull(engine);
        await loadSlim(engine);
        //await loadBasic(engine);
      }).then(() => {
        setInit(true);
      });
    }, []);
    const particlesLoaded = (container) => {
      console.log(container);
    };
  
    const options = useMemo(
      () => ({
        // Background color of the particle container
        background: {
          color: {
            value: "#000", // Set your desired background color
          },
        },
        // Frames per second limit for smoother animation
        fpsLimit: 120,
        // Interaction settings
        interactivity: {
          events: {
            // Enable particle creation on click
            onClick: {
              enable: true,
              mode: "push",
            },
            // Enable repulsion on hover
            onHover: {
              enable: true,
              mode: "repulse",
            },
          },
          modes: {
            // Number of particles created on click
            push: {
              quantity: 4,
            },
            // Repulsion settings on hover
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        // Particle settings
        particles: {
          // Color of the particles
          color: {
            value: "#ffffff", // Set your desired particle color
          },
          // Particle links (lines between particles)
          links: {
            color: "#ffffff", // Set the color of the links
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          // Movement settings
          move: {
            direction: "none", // Set particle movement direction
            enable: true,
            // Out mode settings (boundary behavior)
            outModes: {
              default: "bounce", // Bounce off the boundaries
            },
            random: false,
            speed: 4, // Particle movement speed
            straight: false,
          },
          // Number of particles
          number: {
            density: {
              enable: true, // Enable density-based particle creation
            },
            value: 80, // Adjust the number of particles
          },
          // Opacity settings
          opacity: {
            value: 0.5, // Set particle opacity
          },
          // Particle shape
          shape: {
            type: "circle", // Set the shape of the particles
          },
          // Particle size settings
          size: {
            value: { min: 1, max: 5 }, // Set the range of particle sizes
          },
        },
        // Detect retina displays for better quality on high-resolution screens
        detectRetina: true,
      }),
      []
    );
    
  return (
    <div>
       <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />
    </div>
  )
}

export default BackgroundAnimation
