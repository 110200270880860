import React, { useState } from 'react';
import RecruitmentForm from './recruitmentForm';
import RecruitmentDetails from './RecruitmentDetails';
const AddRecruitmentForm = () => {
  const [selectedUploadType, setSelectedUploadType] = useState('excel');

  return (
    <div className="container mx-auto p-8">
      <div className="flex flex-col md:flex-row items-center mb-8">
        <button
          className={`mr-0 md:mr-4 mb-4 md:mb-0 px-6 py-3 rounded-full focus:outline-none ${
            selectedUploadType === 'excel' ? ' text-white bg-blue-950' : 'bg-gray-200 text-black shadow-lg'
          }`}
          onClick={() => setSelectedUploadType('excel')}
        >
          <span className="text-base font-medium">Recruitment Details</span>
        </button>
        <button
          className={`mr-0 md:mr-4 mb-4 md:mb-0 px-6 py-3 rounded-full focus:outline-none ${
            selectedUploadType === 'form' ? ' text-white bg-blue-950' : 'bg-gray-200 text-black shadow-lg'
          }`}
          onClick={() => setSelectedUploadType('form')}
        >
          <span className="text-base font-medium">Form Upload</span>
        </button>
      </div>

      {selectedUploadType === 'excel' && <RecruitmentDetails />}
      {selectedUploadType === 'form' && <RecruitmentForm />}
      
    </div>
  );
};

export default AddRecruitmentForm;
