import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeFormUpload = () => {
  const initialFormData = {
    employeeName: "",
    dateOfJoining: "",
    fatherName: "",
    role: "",
    selectedDepartment: "",
    salary: "",
    email: "",
    cnic: "",
    emergencyContact: ""
    // timeShift: ""
  };

  const [formData, setFormData] = useState(
    JSON.parse(sessionStorage.getItem("formData")) || initialFormData
  );

  const [departments, setDepartments] = useState([]);
  const [errors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  // const [timeShifts, setTimeShifts] = useState([
  //   "01:00pm TO 10:00pm",
  //   "03:00pm TO 12:00am"
  // ]);

  // useEffect(() => {
  //   const fetchDepartments = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       const response = await axios.get(
  //         "http://localhost:5000/api/attendance/getDepartments",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       setDepartments(response.data);
  //     } catch (error) {
  //       console.error(error);
  //       toast.error('Error fetching departments. Please try again.');
  //     }
  //   };
  //   fetchDepartments();
  // }, []);

  useEffect(() => {
    sessionStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setFormErrors({
      ...errors,
      [e.target.name]: ""
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ['employeeName', 'role', 'department', 'cnic', 'email', 'salary', 'dayStart', 'dayEnd'];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`);
        return;
      }
    }
    setLoading(true);
  
    const payload = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value.trim() === '' ? "NP" : value])
    );
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://backendcc.marsbpo.org/api/employees/create",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 201) {
        toast.success('Employee added successfully!');
        setFormData(initialFormData);
      } else {
        throw new Error("Failed to add employee");
      }
    } catch (error) {
      console.error("Error adding employee:", error.message);
      toast.error('Failed to add employee. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    
    <div className="container mx-auto p-8">
       <ToastContainer />
      <form style={{ backgroundColor: '#F4F4F4' }}  className="max-w-2xl mx-auto p-12 shadow-md" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 mt-12 gap-14">
          {/* Name */}
          <div>
            <label
              htmlFor="employeeName"
              className="block text-sm font-medium text-blue-900"
            >
              Name
            </label>
            <input
              type="text"
              id="employeeName"
              name="employeeName"
              value={formData.employeeName}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Employee Name"
            />
            {errors.employeeName && (
              <p className="text-red-500 text-xs mt-1">{errors.employeeName}</p>
            )}
          </div>

          {/* Date of Joining */}
          <div>
            <label
              htmlFor="dateOfJoining"
              className="block text-sm font-medium text-blue-900"
            >
              Date of Joining
            </label>
            <input
              type="date"
              id="dateOfJoining"
              name="dateOfJoining"
              value={formData.dateOfJoining}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
            />
            {errors.dateOfJoining && (
              <p className="text-red-500 text-xs mt-1">{errors.dateOfJoining}</p>
            )}
          </div>

          {/* Father's Name */}
          <div>
            <label
              htmlFor="fatherName"
              className="block text-sm font-medium text-blue-900"
            >
              Father's Name
            </label>
            <input
              type="text"
              id="fatherName"
              name="fatherName"
              value={formData.fatherName}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Father's Name"
            />
            {errors.fatherName && (
              <p className="text-red-500 text-xs mt-1">{errors.fatherName}</p>
            )}
          </div>

          {/* Role */}
          <div>
            <label
              htmlFor="role"
              className="block text-sm font-medium text-blue-900"
            >
              Role
            </label>
            <input
              type="text"
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Role"
            />
            {errors.role && (
              <p className="text-red-500 text-xs mt-1">{errors.role}</p>
            )}
          </div>

          {/* Department */}
          <div>
            <label
              htmlFor="selectedDepartment"
              className="block text-sm font-medium text-blue-900"
            >
              Department
            </label>
            <select
              id="selectedDepartment"
              name="selectedDepartment"
              value={formData.selectedDepartment}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
            >
              <option value="">Select Department</option>
              <option value="Management">Management</option>
              <option value="Final Expense">Final Expense</option>
            </select>
            {errors.selectedDepartment && (
              <p className="text-red-500 text-xs mt-1">{errors.selectedDepartment}</p>
            )}
          </div>

          {/* Salary */}
          <div>
            <label
              htmlFor="salary"
              className="block text-sm font-medium text-blue-900"
            >
              Salary
            </label>
            <input
              type="number"
              id="salary"
              name="salary"
              value={formData.salary}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Salary"
            />
            {errors.salary && (
              <p className="text-red-500 text-xs mt-1">{errors.salary}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-blue-900"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Email"
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>

          {/* CNIC */}
          <div>
            <label
              htmlFor="cnic"
              className="block text-sm font-medium text-blue-900"
            >
              CNIC
            </label>
            <input
              type="text"
              id="cnic"
              name="cnic"
              value={formData.cnic}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Cnic"
            />
            {errors.cnic && (
              <p className="text-red-500 text-xs mt-1">{errors.cnic}</p>
            )}
          </div>

          {/* Time Shift */}
          {/* <div>
            <label
              htmlFor="timeShift"
              className="block text-sm font-medium text-blue-900"
            >
              Time Shift
            </label>
            <select
              id="timeShift"
              name="timeShift"
              value={formData.timeShift}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
            >
              <option value="">Select Time Shift</option>
              {timeShifts.map((shift) => (
                <option key={shift} value={shift}>
                  {shift}
                </option>
              ))}
            </select>
            {errors.timeShift && (
              <p className="text-red-500 text-xs mt-1">{errors.timeShift}</p>
            )}
          </div> */}

          {/* Emergency Contact */}
          <div>
            <label
              htmlFor="emergencyContact"
              className="block text-sm font-medium text-blue-900"
            >
              Emergency Contact
            </label>
            <input
              type="text"
              id="emergencyContact"
              name="emergencyContact"
              value={formData.emergencyContact}
              onChange={handleChange}
              className="mt-1 p-2 w-full border-b focus:outline-none"
              placeholder="Emergency Contact"
            />
            {errors.emergencyContact && (
              <p className="text-red-500 text-xs mt-1">{errors.emergencyContact}</p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="col-span-2 w-full bg-blue-950 text-white p-2 rounded-md"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeFormUpload;
