import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUserTimes, FaUserCheck, FaBed, FaClock, FaUsers, FaHome } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const Card = ({ icon, bgColor, title, subtitle, onClick }) => {
  return (
    <div style={{ backgroundColor: '#F4F4F4', transition: 'opacity 0.3s ease' }} className={`flex flex-col items-center justify-center p-6 shadow-lg rounded-lg border-black hover:shadow-xl transition duration-300 ease-in-out`} onClick={onClick}>
    <div className={`flex items-center justify-center h-16 w-16 rounded-full mb-4 shadow-md border border-gray-300`} style={{ backgroundColor: bgColor }}>
      {icon}
    </div>
    <div className="text-center">
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      <p className="text-gray-700">{subtitle}</p>
    </div>
  </div>
  );
};

const MainCards = ({date }) => {
  const [presentCount, setPresentCount] = useState("");
  const [absentCount, setAbsentCount] = useState("");
  const [leaveCount, setLeaveCount] = useState("");
  const [lateCount, setLateCount] = useState("");
 
  const [totalEmployees, setTotalEmployees] = useState("")
  const navigate = useNavigate()
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get(`https://backendcc.marsbpo.org/api/attendance/attendance-summary/${date}`,{
          headers:{
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data;
        setPresentCount(data.presentCount);
        setAbsentCount(data.absentCount);
        setLeaveCount(data.leaveCount);
        setLateCount(data.lateCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [date]);

  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      axios.get(`https://backendcc.marsbpo.org/api/employees/total-employees`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        const data = response.data;
        setTotalEmployees(data)
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);


  const handleAbsentCardClick = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`https://backendcc.marsbpo.org/api/attendance/get-absent-employees/${date}`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      const absentEmployees = response.data;
            navigate('/absent-employees-details', { state: { absentEmployees } });
    } catch (error) {
      console.error("Error fetching absent employees:", error);
    }
  };
  
  const handlePresentCardClick = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`https://backendcc.marsbpo.org/api/attendance/get-present-employees/${date}`, {
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      const presentEmployees = response.data;
            navigate('/present-employees-details', { state: { presentEmployees } });
    } catch (error) {
      console.error("Error fetching absent employees:", error);
    }
  };

  const handleLeaveCardClick = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`https://backendcc.marsbpo.org/api/attendance/get-leave-employees/${date}`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      const leaveEmployees = response.data;
            navigate('/leave-employees-details', { state: { leaveEmployees } });
    } catch (error) {
      console.error("Error fetching absent employees:", error);
    }
  };
  const handleLateCardClick = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`https://backendcc.marsbpo.org/api/attendance/get-Late-employees/${date}`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      const lateEmployees = response.data;
            navigate('/late-employees-details', { state: { lateEmployees } });
    } catch (error) {
      console.error("Error fetching absent employees:", error);
    }
  };

 

  const handleAllEmployee = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`https://backendcc.marsbpo.org/api/employees/getAllEmployees`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const employees = response.data;

      // Organize employees by department
      const employeesByDepartment = {};
      employees.forEach(employee => {
        const department = employee.department;
        if (!employeesByDepartment[department]) {
          employeesByDepartment[department] = [];
        }
        employeesByDepartment[department].push(employee);
      });
  
      navigate('/all-employees-details', { state: employeesByDepartment });
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  }

  return (
    <div>
      <section className="grid md:grid-cols-2 xl:grid-cols-5 gap-6">
        <Card
          icon={<FaUserTimes color="#fff" size={32} />}
          bgColor="#F87171"
          title={absentCount}
          subtitle="Absent Employees"
          onClick={handleAbsentCardClick}
        />
        <Card
          icon={<FaUserCheck color="#fff" size={32} />}
          bgColor="#4ADE80"
          onClick={handlePresentCardClick}
          title={presentCount}
          subtitle="Present Employees"
        />
        <Card
          icon={<FaBed color="#fff" size={32} />}
          bgColor="#60A5FA"
          title={leaveCount}
          onClick={handleLeaveCardClick}
          subtitle="Leave Count"
        />
        <Card
          icon={<FaClock  color="#fff"size={32} />}
          bgColor="#F59E0B"
          title={lateCount}
          onClick={handleLateCardClick}
          subtitle="Late Employees"
        />
         <Card
          icon={<FaUsers color="#fff" size={32} />}
          bgColor="purple"
          title=  {totalEmployees.totalEmployees}
          onClick={handleAllEmployee}
          subtitle="All Employees"
        />
      </section>
    </div>
  );
};

export default MainCards;
