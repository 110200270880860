import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RemoveInventoryForm = ({ totalInventory,fetchTotalInventory  }) => {
  const [itemsToRemove, setItemsToRemove] = useState({
    headphones: 0,
    laptops: 0,
    mouse: 0,
    keyboards: 0,
    pcs: 0,
    lcds: 0
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setItemsToRemove({ ...itemsToRemove, [id]: parseInt(value) || 0 });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Check if any quantity to remove is negative
      if (Object.values(itemsToRemove).some(quantity => quantity < 0)) {
        throw new Error('Quantity to remove cannot be negative');
      }
  
      // Check if the inventory is already at zero
      const isZeroInventory = Object.keys(itemsToRemove).some(itemName => {
        return totalInventory[itemName] === 0 && itemsToRemove[itemName] > 0;
      });
  
      if (isZeroInventory) {
        throw new Error('Cannot remove inventory because it is already at zero');
      }
  
      const response = await axios.post('https://backendcc.marsbpo.org/api/remove-inventory/remove', itemsToRemove);
      toast.success(response.data.message);
  
      // Reset form inputs
      setItemsToRemove({
        headphones: 0,
        laptops: 0,
        mouse: 0,
        keyboards: 0,
        pcs: 0,
        lcds: 0
      });

      fetchTotalInventory();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container mx-auto p-8">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-6 rounded-lg shadow-xl space-y-4">
        <h1 className='text-2xl font-bold'>Remove Inventory</h1>
        {Object.keys(totalInventory).map((itemName) => (
          <div key={itemName} className="flex flex-col">
            <label htmlFor={itemName} className="mb-1">{itemName.charAt(0).toUpperCase() + itemName.slice(1)}</label>
            <input
              type="number"
              id={itemName}
              value={itemsToRemove[itemName]}
              onChange={handleChange}
              className="w-full px-4 py-2 border-b rounded border-gray-300 focus:outline-none focus:border-blue-500"
            />
          </div>
        ))}
        <button type="submit" className="w-full bg-red-500 text-white py-2 px-4 rounded focus:outline-none hover:bg-red-700">Remove Inventory</button>
      </form>
    </div>
  );
};

export default RemoveInventoryForm;
