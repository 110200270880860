import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { FaEdit } from "react-icons/fa";
import UpdateAttendance from "./UpdateAttendance";

const PresentEmployees = () => {
  const location = useLocation();
  const { presentEmployees } = location.state;
  // State for department filter
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)


  // State for pagination
  const [pageNumber, setPageNumber] = useState(0);
  const employeesPerPage = 25; // Change this value to adjust the number of employees per page

  // Function to handle page change
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Filter out the employees with undefined status
  const validPresentEmployees = presentEmployees.filter(
    (employee) => employee.status === "Present" || "present"
  );

  const departments = [
    ...new Set(validPresentEmployees.map((employee) => employee.department)),
  ];

  // Filter employees based on selected department
  const filteredEmployees = selectedDepartment === "All"
    ? validPresentEmployees
    : validPresentEmployees.filter(employee => employee.department === selectedDepartment);

  // Function to format the date
  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });
  // };

  // Function to handle search by name
  const filteredEmployeesByName = filteredEmployees.filter((employee) =>
    employee.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleBack = () => {
    window.history.back();
  };

  // Function to handle department filter change
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setPageNumber(0); // Reset page number when changing department
  };

    // Function to handle click on update icon button
    const handleUpdateClick = (employee) => {
      setSelectedEmployee(employee);
      setIsModalOpen(true)
    };

  return (
    <div   style={{ backgroundColor: "#F4F4F4" }} className="min-h-screen px-4 py-8">
      <div className="container mx-auto">
        <button
          onClick={handleBack}
          className="flex items-center  rounded-full bg-blue-950 text-white py-2 px-2 mb-4"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>

        <h2 className="text-3xl font-semibold mb-8 text-black">
          Present Employees Details
        </h2>

        <div className="flex flex-col md:flex-row md:justify-between items-center mb-6">
          {/* Department filter */}
          <div className="mb-6 md:mb-0">
            <label
              htmlFor="departmentFilter"
              className="mr-2 md:mt-5 font-bold text-black"
            >
              View Records by Department:
            </label>
            <select
              id="departmentFilter"
              className="border border-black rounded p-2 font-semibold text-black"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="All">All Departments</option>
              {departments.map((department, index) => (
                <option key={index} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>

          {/* Search filter */}
          <div className="md:ml-12">
          <label
          htmlFor="departmentFilter"
          className="mr-2 md:mt-5 font-bold text-black"
        >
          Search by Name:
        </label>
            <input
              type="text"
              placeholder="Enter name ..."
              className="border border-black rounded p-2 font-semibold text-black"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between items-center p-4 bg-blue-950 rounded-lg mb-8">
          <div className="w-1/5 text-center text-xl font-bold text-white">Image</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Name</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Department</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Start Time</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">End Time</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Total Time</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Date</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Status</div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Actions
          </div>
        </div>

        {filteredEmployeesByName.length === 0 ? (
          <div className="text-center text-gray-600">
            No Present employees found.
          </div>
        ) : (
          <>
            {filteredEmployeesByName
              .slice(pageNumber * employeesPerPage, (pageNumber + 1) * employeesPerPage)
              .map((employee) => (
                <div key={employee.id} className="flex bg-white transition-transform transform hover:translate-y-1 shadow-lg rounded-full overflow-x-auto mb-4">
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <FaUser className="h-8 w-8 text-gray-800" />
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <div className="text-center">
                      <div className="font-semibold text-lg text-gray-800">
                        {employee.name}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <div className="text-center">
                      <div className="text-gray-800 text-sm">{employee.department}</div>
                    </div>
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <div className="text-center">
                      <div className="text-gray-800 text-base">{employee.startTime}</div>
                    </div>
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <div className="text-center">
                      <div className="text-gray-800 text-base">{employee.endTime}</div>
                    </div>
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <div className="text-center">
                      <div className="text-gray-800 text-base">{employee.totalHours}</div>
                    </div>
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <div className="text-center">
                      <div className="text-gray-800 text-base">{employee.date}</div>
                    </div>
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                    <div className="text-center">
                      <div className="font-bold text-lg text-green-500">{employee.status.toUpperCase()}</div>
                    </div>
                  </div>
                  <div className="w-1/5 p-2 flex justify-center items-center">
                {/* Update icon button */}
                <FaEdit
                  className="h-6 w-6 text-gray-800 cursor-pointer"
                  onClick={() => handleUpdateClick(employee)}
                />
              </div>
                </div>
              ))}

            {/* Pagination */}
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={Math.ceil(filteredEmployeesByName.length / employeesPerPage)}
              onPageChange={changePage}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </>
        )}
      </div>
      {selectedEmployee && (
        <UpdateAttendance
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          attendanceData={selectedEmployee}
        />
      )}  
    </div>
  );
};

export default PresentEmployees;
