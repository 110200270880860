import React, { useState } from 'react';
import axios from 'axios';
import AttendanceSearch from './AttendanceSearch';
import AttendanceDetails from './AttendanceDetails';
import Reports from './Reports';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Attendance = () => {
  const [file, setFile] = useState(null);
  const [selectedButton, setSelectedButton] = useState('upload');
  const [loading, setLoading] = useState(false);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select a file before uploading.');
      return;
    }
  
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const token = localStorage.getItem('token');
      const apiUrl = `https://backendcc.marsbpo.org/api/attendance/upload`;
  
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
      setLoading(false);
      setFile(null);
      toast.success('File upload successful!');
      console.log('File upload successful:', response.data);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 400 && error.response.data.error === 'Some records in the file already exist in the database') {
        toast.error('File cannot be uploaded due to duplicate data.');
      } else {
        toast.error(error.response ? error.response.data : error.message);
        console.error('File upload failed:', error.response ? error.response.data : error.message);
      }
    }
  };
  
  return (
    <div className="container mx-auto p-8">
          <ToastContainer />

      <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 mb-4">

        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'upload' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('upload')}
        >
          Upload Attendance
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'search' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('search')}
        >
          Search Attendance
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'details' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('details')}
        >
          Export Daily Attendance
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'report' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('report')}
        >
          Generate PDF Reports
        </button>
      </div>

      {selectedButton === 'upload' && (
        <div className="flex justify-center">
          <div
            className={`w-full lg:mt-12 md:w-3/4 lg:w-1/2  p-6 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out ${
              selectedButton === 'upload' ? 'border-t-4 border-blue-950' : ''
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">Attendance Uploader</h2>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} className="mb-4" />
            <button
              onClick={handleUpload}
              className={`w-full bg-blue-950 text-white py-2 px-4 rounded focus:outline-none ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              } hover:bg-opacity-75 focus:shadow-md transition duration-300 ease-in-out`}
              disabled={loading}
            >
              {loading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </div>
      )}

      {selectedButton === 'search' && <AttendanceSearch />}

      {selectedButton === 'details' && <AttendanceDetails />}
      {selectedButton === 'report' && <Reports />}

      </div>
  );
};

export default Attendance;
