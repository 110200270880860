import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { FaPlus, FaTimes } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const IssueInventoryForm = ({ fetchTotalInventory }) => {
  const [employeeName, setEmployeeName] = useState('');
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [issuedBy, setIssuedBy] = useState(''); // New state for "issued by"
  const [loading, setLoading] = useState(false);

  const inventoryItems = [
    'Headphones',
    'Laptops',
    'Mouse',
    'Keyboards',
    'PCs',
    'LCDs'
  ];

  const handleAddItem = () => {
    if (!itemName) {
      toast.error('Please select an item');
      return;
    }
    if (quantity <= 0) {
      toast.error('Quantity must be greater than 0');
      return;
    }
    setItems([...items, { itemName, quantity }]);
    setItemName('');
    setQuantity(0);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!employeeName) {
      toast.error('Please enter employee name');
      return;
    }
    if (items.length === 0) {
      toast.error('Please add at least one item');
      return;
    }
    if (!issuedBy) { // Check if "issued by" is provided
      toast.error('Please enter issued by');
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post('https://backendcc.marsbpo.org/api/issue-inventory/issue', {
        employeeName,
        items,
        issuedBy // Include "issued by" in the request
      });
      toast.success(response.data.message);
      setEmployeeName('');
      setItems([]);
      setIssuedBy(''); // Reset the issuedBy field
      
      fetchTotalInventory();
    } catch (error) {
      toast.error('Failed to issue inventory');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-8">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="max-w-md shadow-xl rounded-lg p-6 mx-auto space-y-4">
        <h2 className="text-2xl font-bold">Issue Inventory</h2>
        <div>
          <label htmlFor="employeeName" className="block">Employee Name</label>
          <input type="text" id="employeeName" value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} className="w-full px-4 py-2 rounded border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div>
          <label htmlFor="itemName" className="block">Item Name</label>
          <select id="itemName" value={itemName} onChange={(e) => setItemName(e.target.value)} className="w-full px-4 py-2 rounded border-gray-300 focus:outline-none focus:border-blue-500">
            <option value="">Select Item</option>
            {inventoryItems.map(item => (
              <option key={item} value={item}>{item}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="quantity" className="block">Quantity</label>
          <input type="number" id="quantity" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} className="w-full px-4 py-2 rounded border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div>
          <label htmlFor="issuedBy" className="block">Issued By</label>
          <input type="text" id="issuedBy" value={issuedBy} onChange={(e) => setIssuedBy(e.target.value)} className="w-full px-4 py-2 rounded border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <button type="button" onClick={handleAddItem} className="w-full flex items-center justify-center bg-blue-950 text-white py-2 px-4 rounded focus:outline-none hover:bg-blue-700">
          <FaPlus className="mr-2" />
          Add Item
        </button>
        <ul className="text-center">
          {items.map((item, index) => (
            <li key={index} className="flex items-center justify-between">
              <span>{item.itemName}: {item.quantity}</span>
              <button type="button" onClick={() => handleRemoveItem(index)}>
                <FaTimes />
              </button>
            </li>
          ))}
        </ul>
        <button type="submit" className="w-full bg-blue-950 text-white py-2 px-4 rounded focus:outline-none hover:bg-blue-700">{loading ? 'Loading...' : 'Issue Inventory'}</button>
      </form>
    </div>
  );
};

export default IssueInventoryForm;
