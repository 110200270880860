import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddInventoryForm from './AddInventoryForm';
import IssueInventoryForm from './IssueInventoryForm';
import { FaKeyboard, FaHeadphones, FaMouse, FaLaptop, FaDesktop, FaPowerOff } from 'react-icons/fa';
import EmployeeInventory from './EmployeeInventory';
import RemoveInventoryForm from './RemoveInventoryForm';
import logo from "../../assets/logo.png";
import html2pdf from "html2pdf.js";

const Inventory = () => {
  const [totalInventory, setTotalInventory] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddInventory, setShowAddInventory] = useState(false);
  const [showIssueInventory, setShowIssueInventory] = useState(false);
  const [showEmployeeInventory, setShowEmployeeInventory] = useState(false);
  const [showRemoveInventory, setShowRemoveInventory] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    fetchTotalInventory();
  }, []);

  const fetchTotalInventory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://backendcc.marsbpo.org/api/inventory/total`);
      setTotalInventory(response.data.data);
    } catch (error) {
      setError('Failed to fetch total inventory count');
    } finally {
      setLoading(false);
    }
  };

  const toggleAddInventory = () => {
    setShowAddInventory(!showAddInventory);
    setShowIssueInventory(false);
    setShowEmployeeInventory(false);
    setShowRemoveInventory(false);
  };

  const toggleIssueInventory = () => {
    setShowIssueInventory(!showIssueInventory);
    setShowAddInventory(false);
    setShowEmployeeInventory(false);
    setShowRemoveInventory(false);
  };

  const toggleEmployeeInventory = () => {
    setShowEmployeeInventory(!showEmployeeInventory);
    setShowAddInventory(false);
    setShowIssueInventory(false);
    setShowRemoveInventory(false);
  };

  const toggleRemoveInventory = () => {
    setShowRemoveInventory(!showRemoveInventory);
    setShowAddInventory(false);
    setShowIssueInventory(false);
    setShowEmployeeInventory(false);
  };

  const exportTotalInventoryToExcel = async () => {
    try {
      const response = await axios.get(`https://backendcc.marsbpo.org/api/inventory/export-total-to-excel`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'total_inventory.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting total inventory to Excel:', error);
    }
  };

  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const generatePDFContent = () => {
    const currentDate = new Date().toLocaleDateString();
  
    let content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Total Inventory Report</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
  
          .header {
            text-align: center;
            margin-bottom: 30px;
          }
  
          .logo {
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
          }
  
          .date {
            text-align: right;
            margin-right: 20px;
          }
  
          .table-container {
            margin: 0 auto;
            padding: 20px;
            width: 90%;
          }
  
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 0 auto;
          }
  
          th, td {
            padding: 10px;
            border: 1px solid #ddd;
            text-align: left;
          }
  
          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <img src="${logo}" alt="Logo" class="logo">
          <h1>Total Inventory Report</h1>
          <p class="date">Date: ${currentDate}</p>
        </div>
        <div class="table-container">
    `;
  
    if (totalInventory && Object.keys(totalInventory).length > 0) {
      content += `
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
      `;
  
      Object.keys(totalInventory).forEach((itemName) => {
        // Capitalize the first letter of the item name
        const capitalizedItemName = itemName.charAt(0).toUpperCase() + itemName.slice(1);
        const count = totalInventory[itemName];
        content += `
          <tr>
            <td>${capitalizedItemName}</td>
            <td>${count}</td>
          </tr>
        `;
      });
  
      content += `
          </tbody>
        </table>
      `;
    } else {
      content += "<p>No Total Inventory data available.</p>"; // Message for empty data
    }
  
    content += `
      </body>
      </html>
    `;
  
    return content;
  };
  

  const exportAllToPDF = async () => {
    try {
      const content = generatePDFContent();

      await html2pdf().from(content).save('Total_Inventory_Report.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };


  return (
    <div className="container bg-white shadow-lg rounded-lg mx-auto px-8 py-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Available Inventory Counts</h2>
        <div>
          <button
            className="bg-blue-950 text-white py-2 px-4 rounded-full focus:outline-none mr-2"
            onClick={exportTotalInventoryToExcel}
          >
            Export to Excel
          </button>
          <button
            className="bg-blue-950 text-white py-2 px-4 rounded-full focus:outline-none"
            onClick={exportAllToPDF}
          >
            Export to PDF
          </button>
        </div>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 gap-4">
          <InventoryItem icon={<FaHeadphones />} label="Headphones" count={totalInventory.headphones} />
          <InventoryItem icon={<FaLaptop />} label="Laptops" count={totalInventory.laptops} />
          <InventoryItem icon={<FaMouse />} label="Mouse" count={totalInventory.mouse} />
          <InventoryItem icon={<FaKeyboard />} label="Keyboards" count={totalInventory.keyboards} />
          <InventoryItem icon={<FaPowerOff />} label="PCs" count={totalInventory.pcs} />
          <InventoryItem icon={<FaDesktop />} label="LCDs" count={totalInventory.lcds} />
        </div>
      )}
      <hr className="my-4" />
      <div className="flex justify-evenly mb-4">
        <button
          className="bg-blue-950 text-white py-2 px-6 rounded-full focus:outline-none mr-4"
          onClick={toggleAddInventory}
        >
          Add Inventory
        </button>

        <button
          className="bg-blue-950 text-white py-2 px-6 rounded-full focus:outline-none mr-4"
          onClick={toggleRemoveInventory}
        >
          Remove Inventory
        </button>

        <button
          className="bg-blue-950 text-white py-2 px-6 rounded-full focus:outline-none"
          onClick={toggleIssueInventory}
        >
          Issue Inventory
        </button>
        <button
          className="bg-blue-950 text-white py-2 px-6 rounded-full focus:outline-none"
          onClick={toggleEmployeeInventory}
        >
          Employee Inventory
        </button>
      </div>
      {showAddInventory && <AddInventoryForm totalInventory={totalInventory} fetchTotalInventory={fetchTotalInventory} />}
      {showRemoveInventory && <RemoveInventoryForm totalInventory={totalInventory} fetchTotalInventory={fetchTotalInventory} />}
      {showIssueInventory && <IssueInventoryForm totalInventory={totalInventory} fetchTotalInventory={fetchTotalInventory}/>}
      {showEmployeeInventory && <EmployeeInventory selectedEmployee={selectedEmployee} fetchTotalInventory={fetchTotalInventory} />}
    </div>
  );
};

const InventoryItem = ({ icon, label, count }) => {
  return (
    <div className="flex flex-col items-center p-4 bg-gray-100 rounded-lg">
      <div className="mb-2 text-3xl">{icon}</div>
      <div className="text-center">
        <p className="font-semibold">{label}</p>
        <p className="text-lg">{count}</p>
      </div>
    </div>
  );
};

export default Inventory;
